import React, { useLayoutEffect } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

// eslint-disable-next-line
export const RequestDemoPageTemplate = ({ title, header, description }) => {
    const initializeHubspotForm = () => {
        if (window.hbspt) {
            window.hbspt.forms.create({
                region: "na1",
                portalId: `${process.env.GATSBY_HUBSPOT_REQUEST_DEMO_PORTALID}`,
                formId: `${process.env.GATSBY_HUBSPOT_REQUEST_DEMO_FORMID}`,
                target: `#hubspotRequestDemoFormContainer`
            })
        } else {
          setTimeout(initializeHubspotForm, 1)
       }
    }

    useLayoutEffect(() => {
        initializeHubspotForm()
    });

  return (
      <>
          <section className="section section--gradient">
              <div className="container">
                  <Helmet>
                      <meta charSet="utf-8" />
                      <title>Request a Demo - Kamsa</title>
                      <meta property="og:title" content="Book a Demo - Kamsa" />
                      <meta name="description" content={`${description}`} />
                      <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>                      
                  </Helmet>
              </div>
          </section>

          <section className="section section--gradient">
              <div className="container is-full-desktop">
                  <div className="block has-text-centered">
                      <h2 className="title is-h1">{title}</h2>
                  </div>
              </div>
          </section>

          <section className="section section--gradient">
              <div className="container is-full-desktop">
                  <div className="columns is-centered">
                      <div className="column is-6">
                          <div className="content">
                              <div id={'hubspotRequestDemoFormContainer'}/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </>
  );
};

RequestDemoPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

const RequestDemoPage = (props) => {
  const { markdownRemark: post } = props.data;

  return (
    <Layout>
      <RequestDemoPageTemplate
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
    </Layout>
  );
};

RequestDemoPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RequestDemoPage;

export const requestDemoPageQuery = graphql`
  query RequestDemoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title,
        description
      }
    }
  }
`;
